import {
  Object3D,
  Mesh,
  TextureLoader,
  DoubleSide,
  Clock,
  LinearFilter,
  ShaderMaterial,
  CylinderBufferGeometry
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
// import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()

    this.image = assets.resources.sgrappaLogo.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 // renderer.capabilities.getMaxAnisotropy()

    this.mouseX = 0
    this.mouseY = Math.PI / 2

    this.geometry = new CylinderBufferGeometry(1, 1, 20, 100, 1, true);

    this.material = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 }
      },
      side: DoubleSide,
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        uniform float uTime;

        void main() {
          vUv = uv;
          vec3 pos = position;

          // if (pos.z > 0.) {
          //   pos.z += 1.;
          //   if (pos.x > 0.) {
          //     pos.x = 1.;
          //   } else {
          //     pos.x = -1.;
          //   }
          // }

          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
      
        uniform sampler2D uTexture;
        uniform float uTime;

        vec2 rotate(vec2 v, float a) {
          float s = sin(a);
          float c = cos(a);
          mat2 m = mat2(c, -s, s, c);
          return m * v;
        }

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 0.5;
          vec2 repeat = -vec2(4., 20.);
          vec2 uv = rotate(vUv, 0.);

          // Alterno i colori della texture
          float alternate = smoothstep(0.5, 0.5, fract(vUv.y * repeat.y / 2.)); // 0 <> 1
          float movement = 1. - alternate * 2.; // -1 <> 1 => Float per il movimento inverso delle texture

          
          // Suddivido UV
          uv = fract(uv * repeat - vec2(time, 0.)); 

          // uv = fract(uv * repeat - vec2(time * movement, 0.)); // Movimenti inversi delle texture

          // Rigiro texture
          uv.y = 1. - uv.y; 

          // Creo le due texture
          vec3 texture1 = 1. - texture2D(uTexture, uv).rgb;
          vec3 texture2 = texture2D(uTexture, uv).rgb;
          vec3 texture = mix(texture1, texture2, alternate);

          // Helper UV
          // texture *= vec3(uv.x, uv.y, 1.);
      
          gl_FragColor = vec4(texture, 1.);
        }
      `,
    });

    this.mesh = new Mesh(this.geometry, this.material);
    this.mesh.position.z = 6
    this.mesh.rotation.x = this.mouseY
    this.add(this.mesh);
  }

  onRaf({ delta }) {
    this.material.uniforms.uTime.value = this.clock.getElapsedTime();

    this.mesh.rotation.z  = lerp(this.mesh.rotation.z, this.mouseX, .07)
    this.mesh.rotation.x  = lerp(this.mesh.rotation.x, this.mouseY, .07)
  }

  onPointerMove({ pointer }) {
    this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, -.02, .02)
    this.mouseY = Math.PI / 2 + mapRange(1 - (pointer.y / window.innerHeight), 0, 1, -.02, .02)
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 100)
  }
}