import {
  Object3D,
  Mesh,
  TextureLoader,
  Clock,
  LinearFilter,
  ShaderMaterial,
  FrontSide,
  PlaneBufferGeometry,
  Group,
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
// import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()

    this.image = assets.resources.sgrappaLogo.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 // renderer.capabilities.getMaxAnisotropy()

    this.image2 = assets.resources.arc.url
    this.texture2 = new TextureLoader().load(this.image2)
    this.texture2.minFilter = LinearFilter
    this.texture2.magFilter = LinearFilter
    this.texture2.anisotropy = 1

    this.image3 = assets.resources.marble.url
    this.texture3 = new TextureLoader().load(this.image3)
    this.texture3.minFilter = LinearFilter
    this.texture3.magFilter = LinearFilter
    this.texture3.anisotropy = 1

    this.mouseX = 0
    this.mouseY = Math.PI / 2
    
    this.group = new Group()
    this.add(this.group)

    this.settings = {
      width: 4,
      height: 3,
      depth: 35,
      horizontalRepeat: {x: 1, y: 10},
      verticalRepeat: {x: 10, y: 1}
    }

    /*------------------------------
    Vertex
    ------------------------------*/
    const vertexShader = /*glsl*/`
      varying vec2 vUv;
      uniform float uTime;

      void main() {
        vUv = uv;
        vec3 pos = position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
      }
    `

    /*------------------------------
    Fragment
    ------------------------------*/
    const fragmentShader = /*glsl*/`
      varying vec2 vUv;
      
      uniform sampler2D uTexture;
      uniform sampler2D uTexture2;
      uniform float uTime;
      uniform vec2 uRepeat;
      uniform float uInverse;
      uniform float uRotate;


      vec2 rotate(vec2 v, float a) {
        float s = sin(a);
        float c = cos(a);
        mat2 m = mat2(c, -s, s, c);
        return m * v;
      }

      #define pi 3.14159265
    
      void main() {
        float time = uTime * .5;
        vec2 repeat = -uRepeat;
        vec2 uv = rotate(vUv, uRotate);
        float alternate = smoothstep(0.5, 0.5, fract(vUv.x * repeat.y / 2. - time)); // 0 <> 1
        uv = fract(uv * repeat + vec2(0., time)); 

        // Rigiro texture
         uv = 1. - uv; 

        vec3 texture1 = texture2D(uTexture, uv).rgb;

        if (uInverse == 1.) texture1 = 1. - texture1;

        // Marble
        // texture1 *= texture2D(uTexture2, fract(vUv * vec2(1., 10.))).rgb;
    
        gl_FragColor = vec4(texture1, 1.);
      }
    `

    // Horizontal
    this.geoHorizontal = new PlaneBufferGeometry(this.settings.width, this.settings.depth)
    this.materialHorizontal = new ShaderMaterial({
      side: FrontSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTexture2: { value: this.texture3 },
        uTime: { value: 0 },
        uRepeat: { value: this.settings.horizontalRepeat},
        uInverse: { value: 0.},
        uRotate: { value: 0.},
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    // Face Top
    this.faceTop = new Mesh(this.geoHorizontal, this.materialHorizontal)
    this.faceTop.position.y = this.settings.height / 2
    this.faceTop.rotation.x = Math.PI / 2
    this.faceTop.position.z = .14
    this.group.add(this.faceTop)

    // Face Bottom
    this.faceBottom = new Mesh(this.geoHorizontal, this.materialHorizontal)
    this.faceBottom.position.y = -this.settings.height / 2
    this.faceBottom.rotation.x = -Math.PI / 2
    this.faceBottom.position.z = .12
    this.group.add(this.faceBottom)

    // Vertical
    this.geoVertical = new PlaneBufferGeometry(this.settings.height + 0.1, this.settings.depth)
    this.materialVertical = new ShaderMaterial({
      side: FrontSide,
      uniforms: {
        uTexture: { value: this.texture2 },
        uTime: { value: 0 },
        uRepeat: { value: this.settings.verticalRepeat},
        uInverse: { value: 0.},
        uRotate: { value: -Math.PI / 2}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
    
    // Face Left
    this.faceLeft = new Mesh(this.geoVertical, this.materialVertical)
    this.faceLeft.position.x = -this.settings.width / 2
    this.faceLeft.rotation.y = Math.PI / 2
    this.faceLeft.rotation.z = Math.PI / 2
    this.group.add(this.faceLeft)

    // Face Right
    this.faceRight = new Mesh(this.geoVertical, this.materialVertical)
    this.faceRight.position.x = this.settings.width / 2
    this.faceRight.rotation.z = Math.PI / 2
    this.faceRight.rotation.y = -Math.PI / 2
    this.group.add(this.faceRight)

    this.group.position.z = -2
  }

  onRaf({ delta }) {
    this.materialHorizontal.uniforms.uTime.value = this.clock.getElapsedTime();

    // this.group.rotation.x  = lerp(this.group.rotation.x, this.mouseX, .07)
    // this.group.rotation.y  = lerp(this.group.rotation.y, this.mouseY, .07)
  }

  onPointerMove({ pointer }) {
    // this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, Math.PI, -Math.PI)
    // this.mouseY = mapRange(1 - (pointer.y / window.innerHeight), 0, 1, Math.PI, -Math.PI)
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 100)
  }
}