import {
  Object3D,
  Mesh,
  TextureLoader,
  DoubleSide,
  Clock,
  LinearFilter,
  ShaderMaterial,
  CircleGeometry,
  CylinderGeometry,
  Group,
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
// import renderer from '/js/renderer'


export default class extends component(Object3D) {
  constructor(obj) {
    super()
    this.obj = obj
    this.checkDisplay()
  }

  init() {
    this.clock = new Clock()

    this.image = assets.resources.sgrappaLogo.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 // renderer.capabilities.getMaxAnisotropy()

    this.mouseX = 0
    this.mouseY = Math.PI / 2

    this.group = new Group()

    /*------------------------------
    Geometries
    ------------------------------*/
    this.settings = {
      radiusOutside: 2,
      radiusInside: .8,
      segments: 80,
      height: 4,
    }

    this.faceGeo = new CircleGeometry( 
      this.settings.radiusOutside,
      this.settings.segments
    )
    this.cylinderGeoOutside = new CylinderGeometry(
      this.settings.radiusOutside, 
      this.settings.radiusOutside, 
      this.settings.height, 
      this.settings.segments, 
      2, 
      true
    )
    this.cylinderGeoInside = new CylinderGeometry(
      this.settings.radiusInside, 
      this.settings.radiusInside, 
      this.settings.height, 
      this.settings.segments, 
      2, 
      true
    )

    const uRadius = mapRange(this.settings.radiusInside, 0, this.settings.radiusOutside, 0, 1)

    /*------------------------------
    Face Material
    ------------------------------*/
    this.faceMat = new ShaderMaterial({
      transparent: true,
      depthWrite: false,
      side: DoubleSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uRadius: { value: uRadius},
        uRepeat: { value: {x: 3., y: 5}}
      },
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        uniform float uTime;

        void main() {
          vUv = uv;
          vec3 pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
      
        uniform sampler2D uTexture;
        uniform float uTime;
        uniform float uRadius;
        uniform vec2 uRepeat;

        vec2 rotate(vec2 v, float a) {
          float s = sin(a);
          float c = cos(a);
          mat2 m = mat2(c, -s, s, c);
          return m * v;
        }

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 0.5;
          vec2 repeat = uRepeat;
          vec2 uv = vUv * 2. - 1.;
          float d = length(uv);

          uv = vec2(d, atan(uv.x, uv.y) / pi);
          uv = rotate(uv, pi * .5);

          float alternate = 1. - smoothstep(0.5, 0.5, fract(uv.y * repeat.y / 2.)); // 0 <> 1
          float movement = 1. - alternate * 2.; // -1 <> 1 => Float per il movimento inverso delle texture

          uv = fract(uv * repeat - vec2(time * movement, 0.)); // Movimenti inversi delle texture
          uv = 1. - uv;

          if (d < uRadius) discard;
          vec3 texture1 = texture2D(uTexture, uv).rgb;
          vec3 texture2 = 1. - texture2D(uTexture, uv).rgb;
          vec3 texture = mix(texture1, texture2, alternate);

          gl_FragColor = vec4(texture, 1.);
        }
      `,
    });

    this.cylinderMatOutside = new ShaderMaterial({
      side: DoubleSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uRepeat: { value: {x: 1., y: 14}}
      },
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        uniform float uTime;

        void main() {
          vUv = uv;
          vec3 pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
        
        uniform sampler2D uTexture;
        uniform float uTime;
        uniform vec2 uRepeat;


        vec2 rotate(vec2 v, float a) {
          float s = sin(a);
          float c = cos(a);
          mat2 m = mat2(c, -s, s, c);
          return m * v;
        }

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 2.5;
          vec2 repeat = -uRepeat;
          vec2 uv = rotate(vUv, pi * .5);
          float alternate = smoothstep(0.5, 0.5, fract(vUv.x * repeat.y / 2. - time * .5)); // 0 <> 1
          uv = fract(uv * repeat + vec2(0., time)); 


          // Rigiro texture
          uv = 1. - uv; 

          vec3 texture1 = 1. - texture2D(uTexture, uv).rgb;
          vec3 texture2 = texture2D(uTexture, uv).rgb;
          vec3 texture = mix(texture1, texture2, alternate);

          // texture = vec3(uv.x, uv.y, 1.);
      
          gl_FragColor = vec4(texture, 1.);
        }
      `,
    });

    this.cylinderMatInside = new ShaderMaterial({
      side: DoubleSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uRepeat: { value: {x: 1., y: 10}}
      },
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        uniform float uTime;

        void main() {
          vUv = uv;
          vec3 pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
        
        uniform sampler2D uTexture;
        uniform float uTime;
        uniform vec2 uRepeat;


        vec2 rotate(vec2 v, float a) {
          float s = sin(a);
          float c = cos(a);
          mat2 m = mat2(c, -s, s, c);
          return m * v;
        }

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 1.5;
          vec2 repeat = -uRepeat;
          vec2 uv = rotate(vUv, pi * .5);
          float alternate = smoothstep(0.5, 0.5, fract(vUv.x * repeat.y / 2. + time * .5)); // 0 <> 1
          uv = fract(uv * repeat + vec2(0., -time)); 


          // Rigiro texture
          uv.x = 1. - uv.x;

          vec3 texture1 = 1. - texture2D(uTexture, uv).rgb;
          vec3 texture2 = texture2D(uTexture, uv).rgb;
          vec3 texture = mix(texture1, texture2, alternate);

          // texture = vec3(uv.x, uv.y, 1.);
      
          gl_FragColor = vec4(texture, 1.);
        }
      `,
    });


    /*------------------------------
    Geo Positions
    ------------------------------*/
    this.faceTop = new Mesh(this.faceGeo, this.faceMat);
    this.faceTop.rotation.x = Math.PI / 2
    this.faceTop.position.y = this.settings.height / 2
    this.group.add(this.faceTop);

    this.faceBottom = new Mesh(this.faceGeo, this.faceMat);
    this.faceBottom.rotation.x = -Math.PI / 2
    this.faceBottom.position.y = -this.settings.height / 2
    this.group.add(this.faceBottom);

    this.cylinderOutside = new Mesh(this.cylinderGeoOutside, this.cylinderMatOutside);
    this.group.add(this.cylinderOutside);
    
    this.cylinderInside = new Mesh(this.cylinderGeoInside, this.cylinderMatInside);
    this.group.add(this.cylinderInside);

    this.add(this.group);
    this.group.rotation.z = Math.PI / 180 * 30
    this.group.rotation.x = Math.PI / 180 * 30
    this.group.position.z = -5
  }

  checkDisplay() {
    if (this.obj.display === 'outro') {
      this.position.z = 100
    }
  }

  onRaf({ delta }) {
    this.faceMat.uniforms.uTime.value = this.clock.getElapsedTime();
    this.cylinderMatOutside.uniforms.uTime.value = this.clock.getElapsedTime();
    this.cylinderMatInside.uniforms.uTime.value = this.clock.getElapsedTime();


    this.group.rotation.x  = lerp(this.group.rotation.x, this.mouseX, .07)
    this.group.rotation.y  = lerp(this.group.rotation.y, this.mouseY, .07)
  }

  onPointerMove({ pointer }) {
    this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, Math.PI, -Math.PI)
    this.mouseY = mapRange(1 - (pointer.y / window.innerHeight), 0, 1, Math.PI, -Math.PI)
  }

  onScroll({y, limit, playrate}) {
    this.position.y = mapRange(y, 0, window.innerHeight * 2, 0, -5)
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 100)
    
    if (this.obj?.display === 'outro') {
      this.position.y = 0
      this.position.z = mapRange(y, 0, limit, 100, 0)
    }
  }

}