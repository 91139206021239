import { Scene } from 'three';
import { component } from 'bidello';
import Box from './canvas/box';
import Bubble from './canvas/bubble';
import Ciak from './canvas/ciak';
import Cylinder1 from './canvas/cylinder1';
import Cylinder2 from './canvas/cylinder2';
import Spiral from './canvas/spiral';
import Vortex from './canvas/vortex';
import Knots from './canvas/knots';
import camera from './camera';
import gsap from 'gsap/gsap-core';


class Stage extends component(Scene) {
  init() {
    this.items = null
    this.checkItems()
    this.add(camera);
  }

  checkItems(data) {
    const container = data ? data.next.container : document.body
    this.items = [...container.getElementsByClassName('gl__item')]

    this.items.forEach((i, index) => {
      const type = i.dataset.type
      const display = i.dataset.display
      let obj

      switch (type) {
        case 'Box':
          obj = new Box({
            display: display
          })
          break
        case 'Bubble':
          obj = new Bubble({
            display: display
          })
          break
        case 'Ciak':
          obj = new Ciak({
            display: display
          })
          break
        case 'Cylinder1':
          obj = new Cylinder1({
            display: display
          })
          break
        case 'Cylinder2':
          obj = new Cylinder2({
            display: display
          })
          break
        case 'Knots':
          obj = new Knots({
            display: display
          })
          break
        case 'Spiral':
          obj = new Spiral({
            display: display
          })
          break
        case 'Vortex':
          obj = new Vortex({
            display: display
          })
          break
        default:
      }
      this.add(obj);
    })
  }

  onRoute({ data, type }) {
    if (type === 'leave') {
      gsap.to('#gl', {
        duration: .3,
        opacity: 0,
        onComplete: () => {
          this.children.forEach(el => {
            if (el.type === 'Object3D') {
              this.remove(el)
            }
          })
        }
      })
    }

    if (type === 'enter') {
      this.children.forEach(el => {
        if (el.type === 'Object3D') {
          this.remove(el)
        }
      })
      this.checkItems(data)

      gsap.to('#gl', {
        opacity: 1,
        delay: 1,
      })
    }
  }
}

export default new Stage();