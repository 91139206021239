import bidello from 'bidello';
import LocomotiveScroll from 'locomotive-scroll'

class Scroll {
  constructor() {

    this.smoothscroll = new LocomotiveScroll({
      el: document.querySelector('#js-scroll'),
      smooth: true,
      smoothMobile: true,
      touchMultiplier: 1.8,
      getDirection: true,
      getSpeed: true,
    });

    this.smoothscroll.on('scroll', (scroll) => {
      this.onScroll(scroll)
    })

    // window.addEventListener('scroll', this.onScroll)
  }


  rotate(el) {
    window.console.log('rotate ---->', el)
  }


  onScroll(scroll) {
    this.y = scroll?.scroll?.y || (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0)
    this.limit = scroll.limit
    this.speed = scroll.speed
    this.playrate = this.y / this.limit

    bidello.trigger({ name: 'scroll', fireAtStart: true }, {
      y: this.y,
      limit: this.limit,
      speed: this.speed,
      playrate: this.playrate
    })
  }
}

export const scroll = new Scroll();
