import {
  Object3D,
  Mesh,
  TextureLoader,
  DoubleSide,
  Clock,
  LinearFilter,
  ShaderMaterial,
  PlaneBufferGeometry
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()

    this.image = assets.resources.fuckYou.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 // renderer.capabilities.getMaxAnisotropy()

    this.x = 1
    this.y = 1
    this.mouseX = 1
    this.mouseY = 1
    this.dist = 0
    this.oldDist = 0

    this.geometry = new PlaneBufferGeometry(20, 1, 30, 30);

    this.material = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uMouse: { value: 0 }
      },
      name: 'material1',
      side: DoubleSide,
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        varying vec3 vPos;
        uniform float uTime;
        uniform float uMouse;

        float map(float value, float inMin, float inMax, float outMin, float outMax) {
          return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
        }

        mat4 rotationMatrix(vec3 axis, float angle) {
            axis = normalize(axis);
            float s = sin(angle);
            float c = cos(angle);
            float oc = 1.0 - c;
            
            return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                        oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                        oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                        0.0,                                0.0,                                0.0,                                1.0);
        }

        vec3 rotate(vec3 v, vec3 axis, float angle) {
          mat4 m = rotationMatrix(axis, angle);
          return (m * vec4(v, 1.0)).xyz;
        }

        void main() {
          vUv = uv;
          vec3 pos = position;


          float range = .15;
          
          float h1 = map(uMouse, -1., 1., 1. - range, 1. + range);
          float h2 = map(uMouse, -1., 1., 1. + range, 1. - range);
          float dy = map(pos.x, -1., 1., h1, h2);
          pos.y *= dy;

          vUv = uv;

          vPos = pos;

          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
        varying vec3 vPos;
      
        uniform sampler2D uTexture;
        uniform float uTime;

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 0.4;
          vec2 uv = vUv;
          vec2 repeat = vec2(6., 1.);
          uv = fract(uv * repeat - vec2(time, 0.)); 

          vec3 col = 1. - texture2D(uTexture, uv).rgb;


          gl_FragColor = vec4(col, 1.);
        }
      `,
    });

    this.material2 = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uMouse: { value: 0 }
      },
      name: 'material1',
      side: DoubleSide,
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        varying vec3 vPos;
        uniform float uTime;
        uniform float uMouse;

        float map(float value, float inMin, float inMax, float outMin, float outMax) {
          return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
        }

        mat4 rotationMatrix(vec3 axis, float angle) {
            axis = normalize(axis);
            float s = sin(angle);
            float c = cos(angle);
            float oc = 1.0 - c;
            
            return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                        oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                        oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                        0.0,                                0.0,                                0.0,                                1.0);
        }

        vec3 rotate(vec3 v, vec3 axis, float angle) {
          mat4 m = rotationMatrix(axis, angle);
          return (m * vec4(v, 1.0)).xyz;
        }

        void main() {
          vUv = uv;
          vec3 pos = position;


          float range = .15;
          
          float h1 = map(uMouse, -1., 1., 1. - range, 1. + range);
          float h2 = map(uMouse, -1., 1., 1. + range, 1. - range);
          float dy = map(pos.x, -1., 1., h1, h2);
          pos.y *= dy;

          vUv = uv;

          vPos = pos;

          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
        varying vec3 vPos;
      
        uniform sampler2D uTexture;
        uniform float uTime;

        #define pi 3.14159265
      
        void main() {
          float time = uTime * 0.4;
          vec2 uv = vUv;
          vec2 repeat = vec2(3., 1.);
          uv = fract(uv * repeat - vec2(time, 0.)); 

          vec3 col = 1. - texture2D(uTexture, uv).rgb;

          gl_FragColor = vec4(col, 1.);
        }
      `,
    });
    
    this.meshes = []

    for (let i = 0; i < 8; i++) {
      if (i % 2 === 0) {
        this.mesh = new Mesh(this.geometry, this.material)
      } else {
        this.mesh = new Mesh(this.geometry, this.material2)
      }
      this.mesh.position.z = 4
      this.mesh.position.y = -3 + i
      this.add(this.mesh);

      this.meshes.push(this.mesh)
    }
  }

  onRaf({ delta }) {
    this.x = lerp(this.x, this.mouseX, .1)
    this.dist = lerp(this.oldDist, this.dist, .1)
    this.material.uniforms.uTime.value = this.clock.getElapsedTime();
    this.material.uniforms.uMouse.value = this.x
    this.material2.uniforms.uTime.value = -1. * this.clock.getElapsedTime();
    this.material2.uniforms.uMouse.value = -1. * this.x
    this.oldDist = this.dist
  }

  onPointerMove({ pointer }) {
    this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, -1, 1)
    this.mouseY = mapRange(pointer.y / window.innerHeight, 1, 0, -1, 1)


    this.dist = Math.abs(this.mouseX) > Math.abs(this.mouseY) ? this.mouseX : this.mouseY
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 20)
  }
}