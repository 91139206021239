import { component } from 'bidello';

class Meter extends component() {
  init() {
    this.el = document.body.querySelector('#meter')
  }

  onScroll({ playrate }) {
    this.el.style.transform = `scaleX(${playrate})`
  }
}

export default new Meter();
