import {
  Object3D,
  Mesh,
  TextureLoader,
  DoubleSide,
  Clock,
  LinearFilter,
  ShaderMaterial,
  PlaneBufferGeometry
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, clamp, lerp } from '/js/utils/math';
import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()

    this.image = assets.resources.sgrappaLogo.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 //renderer.capabilities.getMaxAnisotropy()

    this.x = 1
    this.y = 0.02
    this.mouseX = 1
    this.mouseY = 0.02
    
    this.settings = {
      width: 2,
      height: 5,
      segments: 80,
    }

    this.geometry = new PlaneBufferGeometry(this.settings.width, this.settings.height, this.settings.segments, this.settings.segments);

    this.material = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uAspect: { value: this.settings.height / this.settings.width },
      },
      name: 'material1',
      side: DoubleSide,
      vertexShader: /*glsl*/`
        varying vec2 vUv;
        uniform float uTime;

        void main() {
          vUv = uv;
          vec3 pos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
        }
      `,
      fragmentShader: /*glsl*/`
        varying vec2 vUv;
        varying vec3 vPos;
      
        uniform sampler2D uTexture;
        uniform float uTime;
        uniform float uAspect;

        #define pi 3.14159265

        vec2 barrelDistort(vec2 pos, float power) {
          float t = atan(pos.y, pos.x);
          float r = pow(length(pos), power);
          pos.x = r * cos(t);
          pos.y = r * sin(t);
          return 0.5 * (pos + 1.0);
        }
      
        void main() {
          float time = uTime;
          vec2 uv = vUv;

          // Repeat
          vec2 repeat = vec2(1., 10.);
          uv.y = fract(uv.y * repeat.y + time); 

          vec3 col1 = 1. - texture2D(uTexture, uv).rgb;


          // Barrel
          vec2 centeredUV = vUv * 2. - 1.;
          centeredUV.y *= uAspect;

          float zoom = 1.8;
          uv = barrelDistort(centeredUV, zoom);
          repeat = vec2(1., 4.);
          uv.y = fract(uv.y * repeat.y + time); 
          vec3 col2 = 1. - texture2D(uTexture, uv).rgb;

          vec3 col = vec3(0.);
          col = mix(col2, col1, smoothstep(.99, 1., length(centeredUV)));


          gl_FragColor = vec4(col, 1.);
        }
      `,
    });
    
    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.position.z = 6
    this.add(this.mesh);

  }

  onRaf({ delta }) {
    this.y = lerp(this.y, this.mouseY, .1)
    this.material.uniforms.uTime.value += this.y;
  }

  onPointerMove({ pointer }) {
    // this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, 1, -1)
    this.mouseY = mapRange(pointer.y / window.innerHeight, 1, 0, 0.1, -0.1)
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 20)
  }
}