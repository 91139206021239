import {
  Object3D,
  Mesh,
  TextureLoader,
  DoubleSide,
  Clock,
  LinearFilter,
  ShaderMaterial,
  TorusBufferGeometry,
  Group,
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
// import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()

    this.image = assets.resources.sgrappaLogo.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    this.texture.anisotropy = 1 // renderer.capabilities.getMaxAnisotropy()

    this.mouseX = 1
    this.mouseY = 0

    this.group = new Group()
    this.geo = new TorusBufferGeometry(1, .3, 80, 80)


    /*------------------------------
    Vertex
    ------------------------------*/
    const vertexShader = /*glsl*/`
      varying vec2 vUv;
      uniform float uTime;

      void main() {
        vUv = uv;
        vec3 pos = position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
      }
    `

    /*------------------------------
    Fragment
    ------------------------------*/
    const fragmentShader = /*glsl*/`
      varying vec2 vUv;
      
      uniform sampler2D uTexture;
      uniform float uTime;
      uniform vec2 uRepeat;
      uniform float uInverse;


      vec2 rotate(vec2 v, float a) {
        float s = sin(a);
        float c = cos(a);
        mat2 m = mat2(c, -s, s, c);
        return m * v;
      }

      #define pi 3.14159265
    
      void main() {
        float time = uTime * .5;
        vec2 repeat = -uRepeat;
        vec2 uv = rotate(vUv, 0.);
        float alternate = smoothstep(0.5, 0.5, fract(vUv.x * repeat.y / 2. - time)); // 0 <> 1
        uv = fract(uv * repeat + vec2(time, 0.)); 

        // Rigiro texture
        // uv.y = 1. - uv.y; 

        vec3 texture1 = texture2D(uTexture, uv).rgb;

        if (uInverse == 1.) texture1 = 1. - texture1;
    
        gl_FragColor = vec4(texture1, 1.);
      }
    `

    this.material1 = new ShaderMaterial({
      side: DoubleSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uRepeat: { value: {x: 4., y: 4}},
        uInverse: { value: 1.}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    this.material2 = new ShaderMaterial({
      side: DoubleSide,
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uRepeat: { value: {x: 4., y: 4}},
        uInverse: { value: 0.}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });


    /*------------------------------
    Geo Positions
    ------------------------------*/
    this.mesh1 = new Mesh(this.geo, this.material1);
    this.mesh1.rotation.x = Math.PI / 2.4
    this.mesh1.position.x = -.5
    this.group.add(this.mesh1);
    
    this.mesh2 = new Mesh(this.geo, this.material2);
    this.mesh2.rotation.y = Math.PI 
    this.mesh2.position.x = .5
    this.mesh2.scale.set(.9, .9, .9)
    this.group.add(this.mesh2);

    this.add(this.group);
    this.group.position.z = 2
  }

  onRaf({ delta }) {
    this.material1.uniforms.uTime.value = this.clock.getElapsedTime();
    this.material2.uniforms.uTime.value = this.clock.getElapsedTime();

    this.group.rotation.x  = lerp(this.group.rotation.x, this.mouseX, .07)
    this.group.rotation.y  = lerp(this.group.rotation.y, this.mouseY, .07)
  }

  onPointerMove({ pointer }) {
    this.mouseX = mapRange(pointer.x / window.innerWidth, 0, 1, Math.PI, -Math.PI)
    this.mouseY = mapRange(1 - (pointer.y / window.innerHeight), 0, 1, Math.PI, -Math.PI)
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 100)
  }
}