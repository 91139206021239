import bidello from 'bidello';
import barba from '@barba/core'
import gsap from 'gsap'
import { scroll } from './bidello/scroll'

class Routing {
  constructor() {
    this.init()
  }

  init() {
    barba.init({
      transitions: [{
        name: 'opacity-transition',
        leave(data) {
          bidello.trigger({ name: 'route', fireAtStart: true }, {
            type: 'leave',
            data: data
          })

          return gsap.to(data.current.container, {
            duration: .3,
            opacity: 0,
            onComplete: () => {
              const self = this
              scroll.smoothscroll.scrollTo('top', 0, 0, [0, 0, 1, 1], true)
            },
          });
        },
        enter(data) {
          bidello.trigger({ name: 'route', fireAtStart: true }, {
            type: 'enter',
            data: data
          })

          gsap.fromTo(data.next.container, {
            opacity: 0,
          }, {
            duration: 1,
            opacity: 1,
            delay: 1,
          })
        }
      }],
    })
  }
}

export default new Routing()