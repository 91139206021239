import {
  Object3D,
  Mesh,
  TextureLoader,
  FrontFace,
  BackSide,
  Clock,
  Group,
  LinearFilter,
  ShaderMaterial,
  PlaneBufferGeometry
} from 'three';

import { component } from 'bidello';
import assets from '../assets';
import { mapRange, lerp } from '/js/utils/math';
// import renderer from '/js/renderer'


export default class extends component(Object3D) {
  init() {
    this.clock = new Clock()
    this.group = new Group()

    this.image = assets.resources.sgrappaLogo2.url
    this.texture = new TextureLoader().load(this.image)
    this.texture.minFilter = LinearFilter
    this.texture.magFilter = LinearFilter
    // this.texture.anisotropy = 1

    this.x = 0.02
    this.mouseX = 0.02
    this.mouseY = Math.PI / 2

    this.geometry1 = new PlaneBufferGeometry(2, .6, 250, 1, 1, true);
    this.geometry2 = new PlaneBufferGeometry(2, .6, 250, 1, 1, true);

    /*------------------------------
    Vertex
    ------------------------------*/
    const vertexShader = /*glsl*/`
      varying vec2 vUv;
      uniform float uTime;

      #define pi 3.14159265

      void main() {
        vUv = uv;
        vec3 pos = position;

        float theta = pos.x * pi * 4.;
        float height = pos.x * 2.5;
        pos.x = sin(theta);
        pos.z = cos(theta);
        pos.y += height;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
      }
    `

    /*------------------------------
    Fragment
    ------------------------------*/
    const fragmentShader = /*glsl*/`
      varying vec2 vUv;
    
      uniform sampler2D uTexture;
      uniform float uTime;
      uniform float uInverse;

      vec2 rotate(vec2 v, float a) {
        float s = sin(a);
        float c = cos(a);
        mat2 m = mat2(c, -s, s, c);
        return m * v;
      }

      #define pi 3.14159265
    
      void main() {
        float time = uTime * 0.5;
        vec2 repeat = -vec2(18., 1.);
        vec2 uv = rotate(vUv, 0.);

        // Alterno i colori della texture
        float alternate = smoothstep(0.5, 0.5, fract(vUv.y * repeat.y / 2.)); // 0 <> 1
        float movement = 1. - alternate * 2.; // -1 <> 1 => Float per il movimento inverso delle texture

        
        // Suddivido UV
        uv = fract(uv * repeat - vec2(time, 0.)); 

        // uv = fract(uv * repeat - vec2(time * movement, 0.)); // Movimenti inversi delle texture

        // Rigiro texture
        uv = 1. - uv; 

        if (uInverse < 1.) {
          uv.x = 1. - uv.x;
        }

        // Creo le due texture
        vec3 texture = texture2D(uTexture, uv).rgb;

        if (uInverse > 0.) {
          texture = 1. - texture;
        }

        // Helper UV
        // texture *= vec3(uv.x, uv.y, 1.);

    
        gl_FragColor = vec4(texture, 1.);
      }
    `

    this.material1 = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uInverse: { value: 1.},
      },
      side: FrontFace,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
    this.material2 = new ShaderMaterial({
      uniforms: {
        uTexture: { value: this.texture },
        uTime: { value: 0 },
        uInverse: { value: 0.},
      },
      side: BackSide,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    this.mesh1 = new Mesh(this.geometry1, this.material1);
    this.mesh2 = new Mesh(this.geometry2, this.material2);

    this.group.position.z = 5.3
    this.add(this.group)
    this.group.add(this.mesh1);
    this.group.add(this.mesh2);
  }

  onRaf({ delta }) {
    

    this.x = lerp(this.x, this.mouseX, .1)
    this.material1.uniforms.uTime.value += this.x;
    this.material2.uniforms.uTime.value += this.x;
  }

  onPointerMove({ pointer }) {
    this.mouseX = mapRange(pointer.x / window.innerWidth, 1, 0, 0.1, -0.1)
  }

  onScroll({y}) {
    this.position.z = mapRange(y, 0, window.innerHeight * 2, 0, 20)
    this.position.y = mapRange(y, 0, window.innerHeight * 2, 0, 4)
    this.rotation.z = mapRange(y, 0, window.innerHeight * 2, 0, Math.PI)
  }
}