import { component } from 'bidello';
import * as helpers from './bidello';
import routing from './routing';
import renderer from './renderer';
import camera from './camera';
import scene from './scene';
import meter from './meter';
import settings from './settings';
import postfx from './postfx/postfx';
import assets from './assets';
import { scroll } from './bidello';


class Site extends component() {
  init() {
    assets.load();
    document.body.appendChild(renderer.domElement);
    renderer.domElement.id = 'gl'
  }

  onRaf() {
    renderer.render(scene, camera);
    // postfx.render(scene, camera);
  }

  onLoadEnd() {
    console.log('finished loader!');
  }
}

new Site();

